Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.blockeduserApiApiContentType = "application/json";
exports.labelTitleText = "blockedusers";
exports.labelBodyText = "blockedusers Body";
exports.BlockeduserApiEndPoint="/block_users/block_users";
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.textInputPlaceHolder = "Enter Text";
exports.contentPlaceHolder = "Enter Content";
exports.configError = "Error";
exports.configErrorTitle = "Please enter a title";
exports.configErrorContent = "Please enter some content";
// Customizable Area End
