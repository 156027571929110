// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Contentmanagement1 from "../../blocks/contentmanagement1/src/Contentmanagement1";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Adminconsole from "../../blocks/adminconsole/src/Adminconsole";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Applelogin1 from "../../blocks/applelogin1/src/Applelogin1";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Chat2 from "../../blocks/chat2/src/Chat2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Analytics1 from "../../blocks/analytics1/src/Analytics1";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Keywordsearch from "../../blocks/keywordsearch/src/Keywordsearch";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Dashboard4 from "../../blocks/dashboard4/src/Dashboard4";
import Analytics from "../../blocks/analytics/src/Analytics";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Googlelogin1 from "../../blocks/googlelogin1/src/Googlelogin1";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Followers from "../../blocks/followers/src/Followers";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";



const routeMap = {
Contentmanagement1:{
 component:Contentmanagement1,
path:"/Contentmanagement1"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Adminconsole:{
 component:Adminconsole,
path:"/Adminconsole"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Applelogin1:{
 component:Applelogin1,
path:"/Applelogin1"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Chat2:{
 component:Chat2,
path:"/Chat2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Analytics1:{
 component:Analytics1,
path:"/Analytics1"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Keywordsearch:{
 component:Keywordsearch,
path:"/Keywordsearch"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Dashboard4:{
 component:Dashboard4,
path:"/Dashboard4"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
Googlelogin1:{
 component:Googlelogin1,
path:"/Googlelogin1"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
Followers:{
 component:Followers,
path:"/Followers"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;